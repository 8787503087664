import React from 'react';
import Footer from './Footer';
import Recentblog from './Recentblog';


const Contact = () => {
  return (
    <>

<center>
        <h1>Contact Us </h1>
      </center>
      <div className='contact'>
        

        <form className='contact-form'>

          <label htmlFor='name'>Name:</label>
          <input type='text' id='name' name='name' />

          <label htmlFor='email'>Email:</label>
          <input type='email' id='email' name='email' />

          <label htmlFor='message'>Message:</label>
          <textarea id='message' name='message'></textarea>

          <label htmlFor='Mobile'>Mobile:</label>
          <textarea id='Mobile' name='Mobile'></textarea>

          <button type='submit'>Submit</button>

        </form>

      </div>

      <Recentblog />
      <Footer />
    </>
  );
}

export default Contact;
