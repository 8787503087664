import React from 'react';

const Recentblog = () => {
    return (
        <div className='slide'>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7119.740869028346!2d80.948875!3d26.844073!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfd06392cfd07%3A0x75650a8648c3742f!2sCivil%20Dental%20Clinic!5e0!3m2!1sen!2sus!4v1705656542191!5m2!1sen!2sus"
                style={{ width: '100%',  height: '100%'}}
            ></iframe>
        </div>
    );
}

export default Recentblog;
