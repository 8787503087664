import React from 'react';
import ser1 from './Img/ser1.png';
import ser2 from './Img/ser2.png';
import ser3 from './Img/ser3.png';
import ser4 from './Img/ser4.png';
import ser5 from './Img/ser5.png';
import ser6 from './Img/ser6.png';
import ser7 from './Img/ser7.png';



const servicesData = [
  {
    title: 'Cosmetic Dentistry Surgery',
    content: 'Cosmetic dentistry is a method of professional oral care that focuses on improving the appearance of your teeth. And although cosmetic dentistry procedures are usually elective rather than essential, some treatment cases also provide restorative benefits.',
    image: ser1,
  },
  {
    title: 'Prosthodontics',
    content: 'Prosthodontics is the branch of dentistry focused on making dental prostheses. A prosthodontist specializes in making dentures, crowns, bridges and other custom-made oral appliances.',
    image: ser2,
  },
  {
    title: 'Gum & Root Canal Treatment',
    content: 'Root canal treatment can help with gums and the underlying jawbone. Read on to learn more about this endodontic treatment. This article discusses root canal treatment and how it can improve the health of the tooth receiving the treatment as well as the gums that support it.',
    image: ser3,
  },
  {
    title: 'General & Pediatric Dentistry',
    content: '“Pediatric dentistry is an age-defined specialty that provides. both primary and comprehensive preventive and therapeutic. oral health care for infants and children through adolescence, including those with special health care needs.”',
    image: ser4,
  },
  {
    title: 'Dentures Crown & Bridge',
    content: 'Both crowns and most bridges are fixed prosthetic devices. Unlike removable devices such as dentures, which you can take out and clean daily, crowns and bridges are cemented onto existing teeth or implants, and can only be removed by a dentist.',
    image: ser5,
  },
  {
    title: 'Orthodontic Braces & Dental Implants',
    content: 'Dental implants can t be moved but as long as your implant is already in the right position, we can happily move the surrounding teeth using a fixed brace or clear aligner system such as Invisalign.',
    image: ser6,
  },
  {
    title: 'Oral Surgical & Teeth Whitening',
    content: 'While implants will be a direct replacement of the tooth that has been lost, braces (orthodontics) will manoeuvre teeth in a particular direction, leading to the closure of the space that has been left since the tooth was lost.',
    image: ser7,
  },
];

const Service = () => {
  return (
    <>
      <br /><br />
      <center>
        <h1>Our Treatment </h1>
      </center>

      <div className='services'>
        {servicesData.map((service, index) => (
          <div key={index} className='service-item'>
            <img src={service.image} alt={service.title} />
            <h3>{service.title}</h3>
            <p>{service.content}</p>
          </div>
        ))}
      </div>

     

    </>
  );
}

export default Service;
