import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink, Routes, Route } from 'react-router-dom';
import bg1 from './Img/banner3.jpg';
import bg2 from './Img/banner2.jpg';

const AppNavbar = () => {
  const [bgIndex, setBgIndex] = useState(0);
  const backgroundImages = [bg1, bg2];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBgIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const headerStyle = {
    backgroundImage: `url(${backgroundImages[bgIndex]})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '550px',
  };

  return (
    <div className="header" style={headerStyle}>
      <Navbar collapseOnSelect expand="lg" variant="dark">
        <Navbar.Brand href="Home">Civil Dental Clinic</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <NavLink to="/" className="nav-link" activeClassName="active" exact>
              Home
            </NavLink>
            <NavLink to="/about" className="nav-link" activeClassName="active">
              About
            </NavLink>
            <NavLink to="/service" className="nav-link" activeClassName="active">
              Service
            </NavLink>
            <NavLink to="/gallery" className="nav-link" activeClassName="active">
              Gallery
            </NavLink>
            <NavLink to="/contact" className="nav-link" activeClassName="active">
              Contact
            </NavLink>
           
          </Nav>
        </Navbar.Collapse>
      </Navbar>
     
      <h1> Dr. Anil Kumar </h1>
      <h2>Welcome Civil Dental Clinic </h2>
      <p>DENTAL CLINIC </p>

      
    </div>
  );
};

export default AppNavbar;
