import React from 'react'

import Welcome from './Welcome'
import Rooms from './Rooms'
import Recentblog from './Recentblog'
import Footer from './Footer'
import Chart from './Chart'
import Service from './Service'


const Home = () => {
  return (



    <div className='main'>

  
      <Welcome />
      <Rooms />
      <Service/>
      <Chart />
      <Recentblog />
      <Footer />


    </div>



  )
}

export default Home   