import React from 'react'

import Room1 from './Img/tem1.jpg'
import Room2 from './Img/team2.jpg'
import Room3 from './Img/team3.jpg'
import Room4 from './Img/room-4.jpg'
import Room5 from './Img/room-5.jpg'
import Room6 from './Img/room-6.jpg'

const Rooms = () => {
    return (

        <div className='ourroom'>

            <h1>Meet Our Teem </h1>

            <div className='rooms'>

                <div className='rooms2'>
                    <img src={Room1} />

                    <h5>Dr Anil Kumar</h5>
                   
                    <p>Meet Dr. Anil Kumar, a highly experienced dentist with a focus on implantology and general dentistry. With over 20 years of expertise, he offers a wide range of dental services, including advanced endodontics, orthodontics, laser therapy, extractions, surgical gum disease treatments, and various denture options. Dr. Kumar is dedicated to enhancing your oral health and smile.</p>
                    
                </div>


                <div className='rooms2'>
                    <img src={Room2} />

                    <h5>Dr Sunil Kumar</h5>
                    
                    <p>Meet Dr. Sunil Kumar, a highly experienced dentist with over 16 years of expertise. Specializing in General Dental Practice, Advanced Endodontics, Extraction of teeth, Crown and bridges, and all surgical procedures, Dr. Kumar is dedicated to providing comprehensive dental care to enhance your oral health and well-being.</p>
                  
                </div>

                <div className='rooms2'>
                    <img src={Room3} />
                    <h5>Richa Singh </h5>
                   <p>Introducing Dr. Richa Singh, a highly specialized dentist with over 6 years of expertise in Orthodontics and Dentofacial Orthopedics, complemented by 10 years of general practice. Their commitment in dentistry makes her your trusted choice for achieving a healthier, more aligned smile.</p>
                </div>

            </div>


          




        </div>
    )
}

export default Rooms
