import React from 'react';

import Footer from './Footer';

import gal1 from './Img/gal1.png';
import gal2 from './Img/gal2.jpeg';
import gal3 from './Img/gal3.png';
import gal4 from './Img/gal4.png';
import gal5 from './Img/gal5.png';
import gal6 from './Img/gal6.jpeg';
import gal7 from './Img/gal7.png';
import gal8 from './Img/gal8.jpeg';
import gal9 from './Img/gal9.png';
import gal10 from './Img/gal10.jpeg';
import gal11 from './Img/gal11.png';
import gal12 from './Img/gal12.jpeg';
import gal13 from './Img/gal13.png';
import gal14 from './Img/gal14.jpeg';
import gal15 from './Img/gal15.png';
import gal16 from './Img/gal16.jpeg';
import gal17 from './Img/gal17.png';

const Gallery = () => {
  const galleryImages = [gal1, gal2, gal3, gal4, gal5, gal6, gal7, gal8, gal9, gal10, gal11, gal12, gal13, gal14, gal15, gal16, gal17];

  return (
   <>

   <center>
    <h1>Gallery</h1>
   </center>
    <div className='gallery'>
      {galleryImages.map((image, index) => (
        <img key={index} src={image} alt={`gallery-img-${index + 1}`} className='gallery-image' />
      ))}
    </div>
   
   <Footer/>
   </>
  );
};

export default Gallery;
