import React from 'react'
import imsta from './Img/shivpal.png';

const Welcome = () => {
    return (
        <div className='welcome'>

            <div className='welcome2'>
                <img src={imsta} />
            </div>

            <div className='welcome3'>
                <h3>Dr. Anil Kumar (Civil Dental Clinic)</h3>
                <h1>Welcome To Civil Dental  Clinic </h1>

                <p>Dr. Anil Kumar (Civil Dental Clinic) is  Located  Hazratganj is one of the most preferred dentist in  Lucknow. The doctor is known to have extensive dental training and to treat all dental problems with efficiency, dedication and precision. The clinic is frequently visited by patients residing in Lucknow as well as patients from across the Uttar Pradesh. It also has plans on expanding its business in the years to come, owing to its immense success and widespread popularity. </p>

                <p>The doctor aims at focusing on the patient’s satisfaction and making it a pleasant experience for them and has earned a loyal clientele over the years. Their state-of-the-art clinic is well-equipped with the latest of technological instruments to provide high-quality dental treatment to the patients. It can also be easily found as it is in close proximity of 5 Park Road.</p>

                <button>Read More </button>

            </div>

        </div>
    )
}

export default Welcome