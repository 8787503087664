import React from 'react'

const Footer = () => {
    return (

        <>

            <div className='footer'>

                <div className='foot2'>
                    <h1>Civil Dental Clinic  </h1>
                    <br /><br />
                    <p>Dr. Anil Kumar (Civil Dental Clinic) is Located Hazratganj is one of the most preferred dentists in Lucknow. The doctor is known to have extensive read more..</p>
                </div>

                <div className='foot2'>
                    <h1>Useful Links</h1>

                    <br /><br />

                    <p>About</p>
                    <p>Contact</p>
                    <p>Gallery</p>
                    <p>Services</p>

                </div>

                <div className='foot2'>
                    <h1>Open Hours</h1>
                    <br /><br />
                    <p>Mon-Sat : 10:00AM – 03:00PM |</p>
                    <p>05:00PM-08:30PM </p>
                    <p>Sun : 10:00AM – 03:00PM </p>
                    
                </div>


                <div className='foot2'>

                    <h1>Reach Us</h1>

                    <br/> <br/>
                    
                    <p> <i class="fa fa-map-marker"></i> 2nd Floor, Af Tower, 5 Park Road, Hazratganj, Lucknow – 226001 </p>
                    <p> <i class="fa fa-phone"></i> +91-9335382264 </p>
                    <p><i class="fa fa-phone"></i> +91-8299834682</p>
                    <p> <i class="fa fa-envelope"></i> anilaryan2008@gmail.com</p>

                </div>

            </div>


            <div className='diaclamer'>

                <h1><i class="fa fa-facebook"></i></h1>
                <h1><i class="fa fa-twitter"></i></h1>
                <h1><i class="fa fa-instagram"></i></h1>

                <p> Civil Dental Clinic  Copyright ©2024 All rights reserved | Developed By Creative Digital Word </p>
            </div>



        </>
    )
}

export default Footer