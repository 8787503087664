import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Component/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Home from './Component/Home';
import About from './Component/About';
import Contact from './Component/Contact';
import Gallery from './Component/Gallery';
import Service from './Component/Service';


function App() {

  return (

    <div className='main'>

      <Router basename={process.env.PUBLIC_URL}>

        <Navbar />

        <Routes>

          <Route path="/" element={<Home />} />
          <Route path="About" element={<About />} />
          <Route path="Contact" element={<Contact />} />
          <Route path="Gallery" element={<Gallery />} />
          <Route path="Service" element={<Service />} />
        </Routes>


      </Router>

    </div>
  );
}

export default App;
